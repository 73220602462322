import { config } from "@hulanbv/dashboard-components";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./styles/global.styles";
import * as ClassValidator from "class-validator";
import * as ClassTransformer from "class-transformer";

const render = async () => {
  config.classTransformer = ClassTransformer;
  config.classValidator = ClassValidator;
  const { Main } = await import("./components/main");
  return ReactDOM.render(
    <StrictMode>
      <Main />
    </StrictMode>,
    document.getElementById("root")
  );
};
render();
