import { cssRule } from "typestyle";

cssRule("*", {
  boxSizing: "border-box",
});

cssRule("body", {
  fontFamily: "'Inter', sans-serif",
  fontSize: 14,
  margin: 0,
  padding: 0,
});
